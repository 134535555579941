import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import CheckinPage from '../modules/event/checkin/CheckinPage'
import CheckinQr from '../modules/event/checkin/CheckinQr'
import PaymentPage from '../modules/payment/PaymentPage'

const EventPage = lazy(async () => await import('../modules/event/EventPage'))
const ProfilePage = lazy(async () => await import('../modules/profile/ProfilePage'))
const DashboardPage = lazy(async () => await import('../modules/dashboard/DashboardPage'))
const AthletePAge = lazy(async () => await import('../modules/athlete/AthletePage'))

const PrivateRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path='checkin/:id/:ref' element={<CheckinPage />} />

      <Route element={<MasterLayout />}>
        <Route path='checkin/:id/qr/:ref' element={<CheckinQr />} />
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='event/*'
          element={
            <SuspensedView>
              <EventPage />
            </SuspensedView>
          }
        />
        <Route
          path='payment/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />
        <Route
          path='athlete/*'
          element={
            <SuspensedView>
              <AthletePAge />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
