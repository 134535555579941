import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { EventAPI } from '../../../api/EventApi'
import { CheckinResponseModel, EventType } from '../../../api/models/event.models'

const CheckinPage: React.FC = () => {
  const [list, setList] = useState<CheckinResponseModel[]>([])
  const [last, setLast] = useState(new Date().getTime())

  const { id, ref } = useParams()
  const { event, isLoading } = EventAPI.useEvent(id)

  const activityMap: Map<string, string> = new Map()
  event?.activities?.forEach(({ id, name }) => activityMap.set(id, name))

  const { checkin, timestamp } = EventAPI.getCheckinDonePer(id, ref, last)

  const removeFromList = (id: string) => {
    setList(list.filter((c) => c.id !== id))
  }

  if (checkin && checkin.length) {
    setLast(timestamp! + 1)
    setList([...list, ...checkin])
  }
  if (isLoading) return <span>Loading...</span>
  return (
    <div className={'card'}>
      <div className='card-body'>
        <div className='row g-5 g-xl-8 mb-5'>
          <div className='table-responsive'>
            {event?.type !== EventType.BTT ? (
              <DefaultTable list={list} activityMap={activityMap} removeFromList={removeFromList} />
            ) : (
              <BagOrientedTable
                list={list}
                activityMap={activityMap}
                removeFromList={removeFromList}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

type typeParams = {
  list: CheckinResponseModel[]
  activityMap: Map<string, string>
  removeFromList: CallableFunction
}

const BagOrientedTable = ({ list, activityMap, removeFromList }: typeParams) => {
  return (
    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
      <thead>
        <tr className='fw-bold text-muted'>
          <th className='min-w-150px'>Nome</th>
          <th className='min-w-120px'>Atividades</th>
          <th className='min-w-60px'>Equipamento</th>
          <th className='min-w-120px'>Saco</th>
          <th className='min-w-20px text-end'>Ações</th>
        </tr>
      </thead>

      <tbody>
        {list &&
          list.map(({ inscription, id }) => {
            const { athlete, activities, equipment } = inscription
            return (
              <tr key={id} id={id}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'></div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {athlete.firstName} {athlete.lastName}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  {activities.activities.map((act) => (
                    <div key={act.id}>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          <a href='#' className='text-muted'>
                            {activityMap.get(act.id)}
                          </a>{' '}
                          Dorsal: {act.dorsal}
                        </span>
                      </div>
                    </div>
                  ))}
                </td>
                <td>
                  {equipment.map((e, i) => (
                    <div key={i}>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          <a href='#' className='text-muted'>
                            <FormattedMessage id={e.equipment} />
                          </a>{' '}
                          <FormattedMessage id={e.size} />
                        </span>
                      </div>
                    </div>
                  ))}
                </td>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='text-dark fw-bold text-hover-primary fs-6'>
                      #{(activities.metadata?.bag?.toString() ?? '').padStart(3, '0')}
                    </span>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        removeFromList(id)
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1 pulse pulse-success'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen037.svg'
                        className='svg-icon-3'
                      />
                      <span className='pulse-ring'></span>
                    </a>
                  </div>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

const DefaultTable = ({ list, activityMap, removeFromList }: typeParams) => {
  return (
    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
      <thead>
        <tr className='fw-bold text-muted'>
          <th className='min-w-150px'>Nome</th>
          <th className='min-w-120px'>Atividades</th>
          <th className='min-w-60px'>Equipamento</th>
          <th className='min-w-20px text-end'>Ações</th>
        </tr>
      </thead>

      <tbody>
        {list &&
          list.map(({ inscription, id }) => {
            const { athlete, activities, equipment } = inscription
            return (
              <tr key={id} id={id}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'></div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {athlete.firstName} {athlete.lastName}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  {activities.activities.map((act) => (
                    <div key={act.id}>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          <a href='#' className='text-muted'>
                            {activityMap.get(act.id)}
                          </a>{' '}
                          Dorsal: {act.dorsal}
                        </span>
                      </div>
                    </div>
                  ))}
                </td>
                <td>
                  {equipment.map((e, i) => (
                    <div key={i}>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          <a href='#' className='text-muted'>
                            <FormattedMessage id={e.equipment} />
                          </a>{' '}
                          <FormattedMessage id={e.size} />
                        </span>
                      </div>
                    </div>
                  ))}
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        removeFromList(id)
                      }}
                      className='btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1 pulse pulse-success'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen037.svg'
                        className='svg-icon-3'
                      />
                      <span className='pulse-ring'></span>
                    </a>
                  </div>
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

export default CheckinPage
