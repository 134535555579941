import { UserModel } from '../../modules/auth'

export enum EventStatus {
  CREATED = 'created',
  OPEN = 'open',
  RUNNING = 'running',
  FINISHED = 'finished',
}

export enum Equipment {
  JERSEY = 'jersey',
  SHORTS = 'shorts',
}

export enum Size {
  CHILDREN = 'children',
  XS = 'xsmall',
  S = 'small',
  M = 'medium',
  L = 'large',
  XL = 'xlarge',
  XXL = 'xxlarge',
  XXXL = 'xxxlarge',
}

export enum EventType {
  RUNNING = 'running',
  BTT = 'btt',
}

export interface FeeConfiguration {
  until: string
  value: number
  configuration: FeeDefinition[]
}

export interface FeeDefinition {
  activities: string[]
  equipment: Equipment[]
}

export type EquipmentConfiguration = {
  until: Date | string
  availability: EquipmentAvailability[]
}

export interface EquipmentAvailability {
  equipment: Equipment
  size: Size[]
}

export interface ActivityMetadata {
  minimumAge?: number
  isAtrp?: boolean
  color?: string
  baseDorsal: number | false
  limit: number
  multiple: boolean
}

export interface Activity {
  id: string
  name: string
  description: string
  date: Date
  metadata: ActivityMetadata
  createdAt: Date
  updatedAt: Date
  deletedAt: Date
  eventId: string
}
export interface IActivity {
  id?: string
  name: string
  description: string
  date: string
  minimumAge?: number
  baseDorsal: number | false
  limit: number
  isAtrp?: boolean
  multi?: boolean
}

export interface EventModel {
  id: string
  name: string
  description: string
  startDate: string
  endDate: string
  inscriptionsStartDate: string
  inscriptionsEndDate: string
  status: EventStatus
  registrationMessage?: string
  validationMessage?: string
  availableEquipment?: EquipmentConfiguration[]
  fee?: FeeConfiguration[]
  type: EventType
  activities?: Activity[]
  inProduction: boolean
  metadata?: { printedList: boolean; referencesGenerated: boolean }
}

export interface EventOverview {
  ageDistribution: Record<string, number>
  cityDistribution: Record<string, number>
  genreDistribution: Record<string, number>
  statusDistribution: Record<string, number>
  inscriptions: { activity: Activity; inscriptions: InscriptionResponseModel[] }[]
  equipmentDistribution: Record<Size, number>[]
}

export enum AddrressType {
  INVOICE = 'invoice',
  PERSONAL = 'personal',
}

export type UserAddress = {
  city: string
  zip: string
  description: string
  type: AddrressType
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export enum Country {
  UnitedStates = 'US',
  Afghanistan = 'AF',
  AlandIslands = 'AX',
  Albania = 'AL',
  Algeria = 'DZ',
  AmericanSamoa = 'AS',
  Andorra = 'AD',
  Angola = 'AO',
  Anguilla = 'AI',
  Antarctica = 'AQ',
  AntiguaAndBarbuda = 'AG',
  Argentina = 'AR',
  Armenia = 'AM',
  Aruba = 'AW',
  Australia = 'AU',
  Austria = 'AT',
  Azerbaijan = 'AZ',
  Bahamas = 'BS',
  Bahrain = 'BH',
  Bangladesh = 'BD',
  Barbados = 'BB',
  Belarus = 'BY',
  Belgium = 'BE',
  Belize = 'BZ',
  Benin = 'BJ',
  Bermuda = 'BM',
  Bhutan = 'BT',
  Bolivia = 'BO',
  BonaireSintEustatiusSaba = 'BQ',
  BosniaAndHerzegovina = 'BA',
  Botswana = 'BW',
  BouvetIsland = 'BV',
  Brazil = 'BR',
  BritishIndianOceanTerritory = 'IO',
  BruneiDarussalam = 'BN',
  Bulgaria = 'BG',
  BurkinaFaso = 'BF',
  Burundi = 'BI',
  Cambodia = 'KH',
  Cameroon = 'CM',
  Canada = 'CA',
  CapeVerde = 'CV',
  CaymanIslands = 'KY',
  CentralAfricanRepublic = 'CF',
  Chad = 'TD',
  Chile = 'CL',
  China = 'CN',
  ChristmasIsland = 'CX',
  CocosKeelingIslands = 'CC',
  Colombia = 'CO',
  Comoros = 'KM',
  Congo = 'CG',
  CongoDemocraticRepublic = 'CD',
  CookIslands = 'CK',
  CostaRica = 'CR',
  CoteDIvoire = 'CI',
  Croatia = 'HR',
  Cuba = 'CU',
  Curaçao = 'CW',
  Cyprus = 'CY',
  CzechRepublic = 'CZ',
  Denmark = 'DK',
  Djibouti = 'DJ',
  Dominica = 'DM',
  DominicanRepublic = 'DO',
  Ecuador = 'EC',
  Egypt = 'EG',
  ElSalvador = 'SV',
  EquatorialGuinea = 'GQ',
  Eritrea = 'ER',
  Estonia = 'EE',
  Ethiopia = 'ET',
  FalklandIslands = 'FK',
  FaroeIslands = 'FO',
  Fiji = 'FJ',
  Finland = 'FI',
  France = 'FR',
  FrenchGuiana = 'GF',
  FrenchPolynesia = 'PF',
  FrenchSouthernTerritories = 'TF',
  Gabon = 'GA',
  Gambia = 'GM',
  Georgia = 'GE',
  Germany = 'DE',
  Ghana = 'GH',
  Gibraltar = 'GI',
  Greece = 'GR',
  Greenland = 'GL',
  Grenada = 'GD',
  Guadeloupe = 'GP',
  Guam = 'GU',
  Guatemala = 'GT',
  Guernsey = 'GG',
  Guinea = 'GN',
  GuineaBissau = 'GW',
  Guyana = 'GY',
  Haiti = 'HT',
  HeardIslandMcdonaldIslands = 'HM',
  HolySeeVaticanCityState = 'VA',
  Honduras = 'HN',
  HongKong = 'HK',
  Hungary = 'HU',
  Iceland = 'IS',
  India = 'IN',
  Indonesia = 'ID',
  Iran = 'IR',
  Iraq = 'IQ',
  Ireland = 'IE',
  IsleOfMan = 'IM',
  Israel = 'IL',
  Italy = 'IT',
  Jamaica = 'JM',
  Japan = 'JP',
  Jersey = 'JE',
  Jordan = 'JO',
  Kazakhstan = 'KZ',
  Kenya = 'KE',
  Kiribati = 'KI',
  Korea = 'KR',
  KoreaDemocraticPeoplesRepublic = 'KP',
  Kuwait = 'KW',
  Kyrgyzstan = 'KG',
  LaoPeoplesDemocraticRepublic = 'LA',
  Latvia = 'LV',
  Lebanon = 'LB',
  Lesotho = 'LS',
  Liberia = 'LR',
  LibyanArabJamahiriya = 'LY',
  Liechtenstein = 'LI',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Macao = 'MO',
  Macedonia = 'MK',
  Madagascar = 'MG',
  Malawi = 'MW',
  Malaysia = 'MY',
  Maldives = 'MV',
  Mali = 'ML',
  Malta = 'MT',
  MarshallIslands = 'MH',
  Martinique = 'MQ',
  Mauritania = 'MR',
  Mauritius = 'MU',
  Mayotte = 'YT',
  Mexico = 'MX',
  Micronesia = 'FM',
  Moldova = 'MD',
  Monaco = 'MC',
  Mongolia = 'MN',
  Montenegro = 'ME',
  Montserrat = 'MS',
  Morocco = 'MA',
  Mozambique = 'MZ',
  Myanmar = 'MM',
  Namibia = 'NA',
  Nauru = 'NR',
  Nepal = 'NP',
  Netherlands = 'NL',
  NewCaledonia = 'NC',
  NewZealand = 'NZ',
  Nicaragua = 'NI',
  Niger = 'NE',
  Nigeria = 'NG',
  Niue = 'NU',
  NorfolkIsland = 'NF',
  NorthernMarianaIslands = 'MP',
  Norway = 'NO',
  Oman = 'OM',
  Pakistan = 'PK',
  Palau = 'PW',
  PalestinianTerritory = 'PS',
  Panama = 'PA',
  PapuaNewGuinea = 'PG',
  Paraguay = 'PY',
  Peru = 'PE',
  Philippines = 'PH',
  Pitcairn = 'PN',
  Poland = 'PL',
  Portugal = 'PT',
  PuertoRico = 'PR',
  Qatar = 'QA',
  Reunion = 'RE',
  Romania = 'RO',
  RussianFederation = 'RU',
  Rwanda = 'RW',
  SaintBarthelemy = 'BL',
  SaintHelena = 'SH',
  SaintKittsAndNevis = 'KN',
  SaintLucia = 'LC',
  SaintMartin = 'MF',
  SaintPierreAndMiquelon = 'PM',
  SaintVincentAndGrenadines = 'VC',
  Samoa = 'WS',
  SanMarino = 'SM',
  SaoTomeAndPrincipe = 'ST',
  SaudiArabia = 'SA',
  Senegal = 'SN',
  Serbia = 'RS',
  Seychelles = 'SC',
  SierraLeone = 'SL',
  Singapore = 'SG',
  SintMaarten = 'SX',
  Slovakia = 'SK',
  Slovenia = 'SI',
  SolomonIslands = 'SB',
  Somalia = 'SO',
  SouthAfrica = 'ZA',
  SouthGeorgiaAndSandwichIsl = 'GS',
  SouthSudan = 'SS',
  Spain = 'ES',
  SriLanka = 'LK',
  Sudan = 'SD',
  Suriname = 'SR',
  SvalbardAndJanMayen = 'SJ',
  Swaziland = 'SZ',
  Sweden = 'SE',
  Switzerland = 'CH',
  SyrianArabRepublic = 'SY',
  Taiwan = 'TW',
  Tajikistan = 'TJ',
  Tanzania = 'TZ',
  Thailand = 'TH',
  TimorLeste = 'TL',
  Togo = 'TG',
  Tokelau = 'TK',
  Tonga = 'TO',
  TrinidadAndTobago = 'TT',
  Tunisia = 'TN',
  Turkey = 'TR',
  Turkmenistan = 'TM',
  TurksAndCaicosIslands = 'TC',
  Tuvalu = 'TV',
  Uganda = 'UG',
  Ukraine = 'UA',
  UnitedArabEmirates = 'AE',
  UnitedKingdom = 'GB',
  UnitedStatesOutlyingIslands = 'UM',
  Uruguay = 'UY',
  Uzbekistan = 'UZ',
  Vanuatu = 'VU',
  Venezuela = 'VE',
  VietNam = 'VN',
  VirginIslandsBritish = 'VG',
  VirginIslandsUS = 'VI',
  WallisAndFutuna = 'WF',
  WesternSahara = 'EH',
  Yemen = 'YE',
  Zambia = 'ZM',
  Zimbabwe = 'ZW',
}

export interface AthleteModel {
  id: string
  firstName: string
  lastName: string
  taxNumber: string
  nationality: Country
  address: UserAddress[]
  contact: string
  emergencyContact?: string
  gender: Gender
  birthdate: string
  user?: UserModel
}

export interface ActivityModel {
  id: string
  name: string
  description?: string
  date: string
  metadata?: { minimumAge?: number; isAtrp?: boolean; multiple?: boolean }
}

export interface ActivityInscription {
  activities: { id: string; rank?: string; dorsal?: string }[]
  metadata?: {
    atrp?: string
    be?: boolean
    bag?: string
  }
}

export interface EquipmentAnnotation {
  height: string
  weight: string
}

export interface EquipmentInscription {
  equipment: string
  size: string
  obs?: EquipmentAnnotation
}

export interface PaymentMetadata {
  type?: PaymentType
  date?: Date
}

export enum PaymentType {
  FREE = 'free',
  OFFER = 'offer',
  EXCHANGE = 'exchange',
  SIBS = 'sibs',
  SEPA = 'sepa',
  DIRECT = 'direct',
  UNPAID = 'unpaid',
  MBWAY = 'mbway',
  MB = 'mb',
}

export interface InscriptionResponseModel {
  id: string
  eventId: string
  eventName?: string
  athlete: AthleteModel
  status: string
  reference?: string
  value?: number
  team?: string
  email?: string
  activities: ActivityInscription
  equipment: EquipmentInscription[]
  payment?: PaymentMetadata
  createdAt: string
  updatedAt: string
}

export interface CheckinResponseModel {
  id: string
  inscription: InscriptionResponseModel
  eventId: string
  createdAt: Date
  validator: string
  metadata?: Record<string, unknown>
  updatedAt: Date
  deletedAt?: Date
}

export interface ICreateEvent {
  id?: string
  type: EventType
  name: string
  description: string
  startDate: string
  endDate: string
  inscriptionsStartDate: string
  inscriptionsEndDate: string
  activities: IActivity[]
  equipment: EquipmentConfiguration[]
  fees: FeeConfiguration[]
  registrationMessage?: string
  validationMessage?: string
}

export function mapActivityToInterface(activity: Activity): IActivity {
  return {
    id: activity.id,
    name: activity.name,
    description: activity.description,
    date: new Date(activity.date).toISOString().slice(0, 10),
    minimumAge: activity.metadata.minimumAge,
    baseDorsal: activity.metadata.baseDorsal,
    limit: activity.metadata.limit,
    isAtrp: activity.metadata.isAtrp,
  }
}

export function mapToInterface(event: EventModel): ICreateEvent {
  return {
    id: event.id,
    type: event.type,
    name: event.name,
    description: event.description,
    startDate: event.startDate,
    endDate: event.endDate,
    inscriptionsStartDate: event.inscriptionsStartDate,
    inscriptionsEndDate: event.inscriptionsEndDate,
    activities: event.activities!.map(mapActivityToInterface),
    equipment: event.availableEquipment ?? [{ until: event.inscriptionsEndDate, availability: [] }],
    fees: event.fee ?? [],
    registrationMessage: event.registrationMessage,
    validationMessage: event.validationMessage,
  }
}

export interface EditInscriptionParams {
  team?: string
  activities: string[]
  metadata: {
    atrp?: string
    be?: boolean
  }
  equipment?: EquipmentInscription[]
}

export interface newInscriptionParams extends EditInscriptionParams {
  athleteId: string
  paymentType?: PaymentType
}
