import axios from 'axios'
import { useState } from 'react'
import useSWR from 'swr'
import { getAuth } from '../modules/auth'
import {
  ActivityMetadata,
  CheckinResponseModel,
  EditInscriptionParams,
  EquipmentConfiguration,
  EventModel,
  EventOverview,
  FeeConfiguration,
  IActivity,
  ICreateEvent,
  InscriptionResponseModel,
  newInscriptionParams,
} from './models/event.models'

type newEventRequest = Pick<
  ICreateEvent,
  | 'name'
  | 'description'
  | 'startDate'
  | 'endDate'
  | 'inscriptionsStartDate'
  | 'inscriptionsEndDate'
  | 'type'
>

type updateEventRequest = Omit<newEventRequest, 'type'>

type newActivitityRequest = Pick<IActivity, 'name' | 'description' | 'date'> & {
  metadata: ActivityMetadata
}

type updateActivitityRequest = newActivitityRequest & { id: string }

type patchEventRequest = Pick<ICreateEvent, 'registrationMessage' | 'validationMessage'> & {
  availableEquipment?: EquipmentConfiguration[]
  fee?: FeeConfiguration[]
}

const fileFetcher = (url: string, method = 'GET', body?: unknown) =>
  fetch(url, {
    headers: {
      authorization: 'Bearer ' + getAuth()?.accessToken,
      'Content-Type': 'application/json',
    },
    method,
    ...(body ? { body: JSON.stringify(body) } : {}),
  })

class EventAPI {
  static API_URL = process.env.REACT_APP_EVENT_MANAGEMENT_API_URL
  static url = `${EventAPI.API_URL}/v1/event`

  static getConfig = (json = false) => {
    return {
      headers: {
        ...(json ? { 'Content-Type': 'application/json' } : {}),
        authorization: 'Bearer ' + getAuth()?.accessToken,
      },
    }
  }

  static fetcher = (url: string) => fetch(url, EventAPI.getConfig()).then((res) => res.json())

  static useEvents() {
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [search, setSearch] = useState('')

    const {
      data: _res,
      error,
      mutate,
    } = useSWR(
      [
        `${EventAPI.url}/list?offset=${
          pageIndex > 1 ? (pageIndex - 1) * pageSize : 0
        }&limit=${pageSize}&plain=${search}`,
        {},
      ],
      EventAPI.fetcher,
      {
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      },
    )

    let events: EventModel[] = []
    let total = 0
    let errors = error

    if (_res) {
      if (Object.keys(_res).includes('description')) {
        errors = _res.description
      } else {
        const res = _res as unknown as { data: EventModel[]; total: number }
        events = res.data
        total = res.total
      }
    }

    const isLoadingMore = !_res && !errors
    const isEmpty = total === 0
    const isReachingEnd = isEmpty || pageIndex * pageSize + events?.length >= total

    return {
      events,
      isLoadingMore,
      isError: !!errors,
      isReachingEnd,
      page: pageIndex,
      firstPage: pageIndex <= 1,
      lastPage: pageIndex * pageSize > total,
      total,
      pageSize,
      search,
      mutate,
      setPageIndex,
      setPageSize,
      setSearch,
    }
  }

  static useEvent(eventId?: string): { event: EventModel | undefined; isLoading: boolean } {
    const { data: event, error } = useSWR(`${EventAPI.url}/${eventId}`, EventAPI.fetcher)

    return { event, isLoading: !event && !error }
  }

  static getEventOverview(eventId?: string): {
    isLoading: boolean
    error: unknown
    event: EventOverview
  } {
    const { data, error } = useSWR(`${EventAPI.url}/${eventId}/overview`, EventAPI.fetcher)

    return { isLoading: !data && !error, error, event: data }
  }

  static async deleteEventById(eventId: string): Promise<{ result: boolean; error?: string }> {
    try {
      await axios.delete(`${EventAPI.url}/${eventId}`, { ...EventAPI.getConfig() })
      return { result: true }
    } catch (e) {
      return { result: false, error: (e as Error).message }
    }
  }

  static async insertEvent(data: newEventRequest): Promise<{ event?: string; error?: string }> {
    try {
      const res = await axios.post(`${EventAPI.url}`, data, EventAPI.getConfig(true))
      return { event: res.data.id }
    } catch (e) {
      return { error: (e as Error).message }
    }
  }

  static async updateEvent(
    eventId: string,
    data: updateEventRequest,
  ): Promise<{ event?: string; error?: string }> {
    const res = await axios.put(`${EventAPI.url}/${eventId}`, data, EventAPI.getConfig(true))

    if (res.status >= 200 && res.status < 300) {
      return { event: eventId }
    }

    return { error: res.data }
  }

  static async createActivities(eventId: string, data: newActivitityRequest[]) {
    const res = await axios.post(`${EventAPI.url}/${eventId}/activity`, data, {
      ...EventAPI.getConfig(true),
    })

    if (res.status >= 200 && res.status < 300) {
      return { activities: res.data }
    }

    return { error: res.data }
  }

  static async updateActivities(eventId: string, data: updateActivitityRequest[]) {
    const res = await axios.put(`${EventAPI.url}/${eventId}/activity`, data, {
      ...EventAPI.getConfig(true),
    })

    if (res.status >= 200 && res.status < 300) {
      return { activities: true }
    }

    return { error: res.data }
  }

  static async patchEvent(eventId: string, data: patchEventRequest) {
    const res = await axios.patch(`${EventAPI.url}/${eventId}`, data, { ...EventAPI.getConfig() })
    if (res.status >= 200 && res.status < 300) {
      return { updated: true }
    }

    return { error: res.data }
  }

  static async stopEvent(eventId: string) {
    const res = await axios.post(`${EventAPI.url}/${eventId}/stop`, {}, EventAPI.getConfig(true))
    if (res.status >= 200 && res.status < 300) {
      return { updated: true }
    }

    return { error: res.data }
  }

  static async startEvent(eventId: string) {
    const res = await axios.post(`${EventAPI.url}/${eventId}/start`, {}, EventAPI.getConfig(true))
    if (res.status >= 200 && res.status < 300) {
      return { updated: true }
    }

    return { error: res.data }
  }

  static getListToCheckin(eventId?: string): {
    isLoading: boolean
    error: unknown
    search: string
    inscriptions: InscriptionResponseModel[]
    mutate: CallableFunction
    setSearch: CallableFunction
  } {
    const [search, setSearch] = useState('')
    const { data, error, mutate } = useSWR(
      `${EventAPI.url}/${eventId}/inscription/checkin${search ? '?plain='.concat(search) : ''}`,
      EventAPI.fetcher,
    )

    return { isLoading: !data && !error, error, setSearch, inscriptions: data, search, mutate }
  }

  static getCheckinDone(eventId?: string): {
    isLoading: boolean
    error: unknown
    search: string
    checkin: CheckinResponseModel[]
    setSearch: CallableFunction
    mutate: CallableFunction
  } {
    const [search, setSearch] = useState('')
    const { data, error, mutate } = useSWR(
      `${EventAPI.url}/${eventId}/inscription/checkin/done${
        search ? '?plain='.concat(search) : ''
      }`,
      EventAPI.fetcher,
    )

    return { isLoading: !data && !error, error, setSearch, checkin: data, search, mutate }
  }

  static async generateReferences(
    eventId: string,
    prefix: number,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      await axios.post(
        `${EventAPI.url}/${eventId}/references`,
        { prefix: prefix.toString() },
        EventAPI.getConfig(true),
      )
      return { result: true }
    } catch (e) {
      return { result: false, error: (e as Error).message }
    }
  }

  static async getFile(eventId: string): Promise<void | string> {
    fileFetcher(`${EventAPI.url}/${eventId}/print`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          res.blob().then((blob) => {
            const filename = res.headers
              .get('Content-Disposition')
              ?.split('filename="')[1]
              .slice(0, -1)
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${filename}.xlsx`)
            link.click()
          })
        } else {
          res.json().then((res) => {
            throw new Error(res.description)
          })
        }
      })
      .catch((err) => {
        return err
      })
  }

  static async getPayments(data: string[]): Promise<void | string> {
    fileFetcher(`${EventAPI.url}/invoice`, 'POST', data)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          res.blob().then((blob) => {
            const filename = res.headers
              .get('Content-Disposition')
              ?.split('filename="')[1]
              .slice(0, -1)
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${filename}.xlsx`)
            link.click()
          })
        } else {
          res.json().then((res) => {
            throw new Error(res.description)
          })
        }
      })
      .catch((err) => {
        return err
      })
  }

  static async getInvoiceFile(eventId: string): Promise<void | string> {
    fileFetcher(`${EventAPI.url}/${eventId}/inscription/invoice`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          res.blob().then((blob) => {
            const filename = res.headers
              .get('Content-Disposition')
              ?.split('filename="')[1]
              .slice(0, -1)
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${filename}.xlsx`)
            link.click()
          })
        } else {
          res.json().then((res) => {
            throw new Error(res.description)
          })
        }
      })
      .catch((err) => {
        return err
      })
  }

  static getCheckinDonePer(
    eventId?: string,
    ref?: string,
    timestamp?: number,
  ): {
    isLoading: boolean
    error: unknown
    checkin: CheckinResponseModel[]
    mutate: CallableFunction
    timestamp?: number
  } {
    const { data, error, mutate } = useSWR(
      `${EventAPI.url}/${eventId}/inscription/checkin/${ref}/${timestamp?.toString()}`,
      EventAPI.fetcher,
      {
        refreshInterval: 5000,
      },
    )

    return {
      isLoading: !data && !error,
      error,
      checkin: data,
      mutate,
      timestamp:
        data && data.length > 0 ? new Date(data[data.length - 1].createdAt).getTime() : timestamp,
    }
  }

  static async printInscriptions(
    eventId: string,
    type: 'insurance' | 'lap' | 'general',
  ): Promise<void | string> {
    fileFetcher(`${EventAPI.url}/${eventId}/inscription/print?type=${type}`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          res.blob().then((blob) => {
            const filename = res.headers
              .get('Content-Disposition')
              ?.split('filename="')[1]
              .slice(0, -1)
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${filename}`)
            link.click()
          })
        } else {
          res.json().then((res) => {
            throw new Error(res.description)
          })
        }
      })
      .catch((err) => {
        return err
      })
  }

  static useInscriptions(eventId: string) {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [search, setSearch] = useState('')

    const { data, error, mutate } = useSWR(
      `${EventAPI.url}/${eventId}/inscription/list?offset=${
        page > 1 ? (page - 1) * pageSize : 0
      }&limit=${pageSize}&${search}`,
      EventAPI.fetcher,
      {
        revalidateOnMount: true,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      },
    )

    const inscriptions = data ? [].concat(...data.data) : []
    const total = data ? data.total : 0

    return {
      inscriptions,
      error,

      search,
      total,
      isLoadingMore: false,
      isReachingEnd: true,
      isRefreshing: false,
      pageSize,
      setPageSize,
      setPage,
      page,
      mutate,
      setSearch,
    }
  }

  static async validate(
    eventId: string,
    inscriptionId: string,
    paymentType: string,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      await axios.post(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}/validate`,
        {
          type: paymentType,
        },
        { ...EventAPI.getConfig(true) },
      )

      return { result: true }
    } catch (err) {
      return { result: false, error: (err as Error).message }
    }
  }

  static async doCheckin(
    eventId: string,
    inscriptionId: string,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      const result = await axios.post(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}/checkin`,
        {},
        {
          ...EventAPI.getConfig(true),
        },
      )

      return { result: result && result.status >= 200 && result.status < 300 }
    } catch (err) {
      return { result: false, error: (err as Error).message }
    }
  }

  static async undoCheckin(
    eventId: string,
    inscriptionId: string,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      const result = await axios.delete(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}/checkin`,
        {
          ...EventAPI.getConfig(),
        },
      )

      return { result: result && result.status >= 200 && result.status < 300 }
    } catch (err) {
      return { result: false, error: (err as Error).message }
    }
  }

  static getInscriptionToEdit(
    eventId: string,
    inscriptionId: string,
  ): {
    inscription?: InscriptionResponseModel
    error?: string
    isLoading: boolean
    mutate: CallableFunction
  } {
    const {
      data: inscription,
      error,
      mutate,
    } = useSWR(`${EventAPI.url}/${eventId}/inscription/${inscriptionId}`, EventAPI.fetcher, {
      revalidateOnMount: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    })

    return { inscription, isLoading: !inscription && !error, mutate }
  }

  static async editInscription(
    eventId: string,
    inscriptionId: string,
    values: EditInscriptionParams,
  ): Promise<{ error?: string }> {
    try {
      await axios.put(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}`,
        values,
        EventAPI.getConfig(true),
      )

      return {}
      // return { error: 'Nao foi possivel executar o seu pedido, por favor tente mais tarde' }
    } catch (_err) {
      if (axios.isAxiosError(_err)) {
        const error = _err.response?.data as { code: number; description: string }
        if (error.code === 4902) {
          return { error: 'O número de contribuinte inserido já se encontra registado.' }
        }

        return { error: error.description }
      }

      return { error: 'Nao foi possivel executar o seu pedido, por favor tente mais tarde' }
    }
  }

  static async getInscription(
    eventId: string,
    inscriptionId: string,
  ): Promise<{ inscription?: InscriptionResponseModel; error?: string }> {
    try {
      const result = await axios.get(`${EventAPI.url}/${eventId}/inscription/${inscriptionId}`, {
        ...EventAPI.getConfig(),
      })

      if (result && result.data) {
        return { inscription: result.data }
      }

      return { error: 'Inscription not found' }
    } catch (err) {
      return { error: (err as Error).message }
    }
  }

  static async deleteInscription(
    eventId: string,
    inscriptionId: string,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      await axios.delete(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}`,
        EventAPI.getConfig(),
      )
      return { result: true }
    } catch (err) {
      return { result: false, error: (err as Error).message }
    }
  }

  static async cancelInscription(
    eventId: string,
    inscriptionId: string,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      await axios.post(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}/cancel`,
        {},
        EventAPI.getConfig(),
      )
      return { result: true }
    } catch (err) {
      return { result: false, error: (err as Error).message }
    }
  }

  static async sendReference(
    eventId: string,
    inscriptionId: string,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      await axios.post(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}/resend`,
        {},
        EventAPI.getConfig(),
      )
      return { result: true }
    } catch (err) {
      return { result: false, error: (err as Error).message }
    }
  }

  static async assignNewRef(
    eventId: string,
    inscriptionId: string,
  ): Promise<{ result: boolean; error?: string }> {
    try {
      await axios.post(
        `${EventAPI.url}/${eventId}/inscription/${inscriptionId}/assignnewref`,
        {},
        EventAPI.getConfig(),
      )
      return { result: true }
    } catch (err) {
      return { result: false, error: (err as Error).message }
    }
  }

  static async newInscription(eventId: string, values: newInscriptionParams) {
    const call = axios.post
    const url = `${EventAPI.url}/${eventId}/inscription/new`

    try {
      const result = await call(url, values, EventAPI.getConfig())

      if (result.status >= 200 && result.status < 300) {
        return {}
      }

      throw new Error()
      // return { error: 'Nao foi possivel executar o seu pedido, por favor tente mais tarde' }
    } catch (_err) {
      if (axios.isAxiosError(_err)) {
        const error = _err.response?.data as { code: number; description: string }
        if (error.code === 4902 || error.code === 4910) {
          return { error: 'O número de contribuinte inserido já se encontra registado.' }
        }
      }

      return { error: 'Nao foi possivel executar o seu pedido, por favor tente mais tarde' }
    }
  }
}

export { EventAPI }
export type { updateEventRequest }
