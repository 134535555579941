import { useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { KTSVG } from '../../../../_metronic/helpers'
import { EventAPI } from '../../../api/EventApi'


const CheckinQr: React.FC = () => {
  const [permission, setPermission] = useState<boolean>()
  const [processing, setProcessing] = useState(false)
  const [selected, setSelected] = useState('environment')
  const { id } = useParams()

  const askPermission = () => {
    const permissions = navigator.mediaDevices.getUserMedia({ video: true })
    permissions
      .then(() => {
        setPermission(true)
      })
      .catch((err) => {
        setPermission(false)
        alert(err.message)
        console.log(`${err.name} : ${err.message}`)
      })
  }

  const handleScan = async (scanData: any, error: any) => {
    if (scanData && scanData !== '') {
      setProcessing(true)
      const { inscription, error } = await EventAPI.getInscription(id!, scanData.text)

      if (inscription && inscription.id) {
        if (inscription.status === 'paid') {
          const { athlete, id: inscriptionId } = inscription

          Swal.fire({
            title: 'Confirmar checkin',
            text: `${athlete.firstName} ${athlete.lastName}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: async () => {
              const { error } = await EventAPI.doCheckin(id!, inscriptionId)

              if (error) {
                Swal.showValidationMessage(`Request failed: ${error}`)
                return false
              }

              return true
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(async function (result) {
            if (result.isConfirmed) {
              Swal.fire('success', 'Checkin com Sucesso!')
            }
            setProcessing(false)
          })

          return
        } else if (inscription.status === 'checkin_done') {
          Swal.fire({
            icon: 'info',
            title: `${inscription.athlete.firstName} ${inscription.athlete.lastName}`,
            html: `Checkin:<b>${inscription.updatedAt}</b>`,
          }).then(() => {
            setProcessing(false)
          })
          return
        } else {
          Swal.fire({ title: 'Inscrição nao validada' }).then(() => {
            setProcessing(false)
          })
        }
      }

      if (error) {
        Swal.fire({ title: 'Error', text: error }).then(() => {
          setProcessing(false)
        })
        return
      }
    }
  }

  if (!permission)
    return (
      <div className={'card'}>
        <div className={'card-header'}>
          <span>
            <a
              onClick={(e) => {
                e.preventDefault()
                askPermission()
              }}
              className='btn btn-large btn-icon btn-primary'
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </a>
          </span>
        </div>
      </div>
    )
  return (
    <div className={'card'}>
      <div className={'card-header'}>
        {' '}
        <select
          onChange={(e) => setSelected(e.target.value)}
          className='form-select form-select-white'
          aria-label='Select example'
        >
          <option value={'environment'}>Back Camera</option>
          <option value={'user'}>Front Camera</option>
        </select>
      </div>
      <div className={'card-body'}>
        {!processing && <QrReader constraints={{ facingMode: selected }} onResult={handleScan} />}
      </div>
    </div>
  )
}

export default CheckinQr
